import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { ArabicFont } from "../enums/ArabicFont";
import { Recitation } from "../models/Recitation";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

const blueColor = "#1f4c9f";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: blueColor,
      dark: blueColor
    }
  }
});

const useStyles = makeStyles(() => ({
  content: {
    margin: "0px 10px 00px 10px"
  },
  formControl: {
    width: "230px"
  },
  spacer: {
    height: "20px"
  },
  title: {
    paddingBottom: "5px"
  }
}));

interface DialogSettingsProps {
  isOpen: boolean;
  handleCloseDialog: () => void;

  selectedRecitationCode: string;
  handleRecitationChange: Function;
  recitations: Recitation[];

  selectedFontScaling: number;
  handleFontScalingChange: Function;
  fontScalings: number[];

  verseRepeat: number;
  handleVerseRepeatChange: Function;
  verseRepeats: number[];

  selectedAutoScroll: boolean;
  handleAutoScrollChange: Function;

  selectedArabicFont: ArabicFont;
  handleArabicFontChange: Function;

  autoAdvanceChapter: boolean;
  handleAutoAdvanceChapter: Function;
}

function DialogSettings(props: DialogSettingsProps) {
  const classes = useStyles(props);

  return (
    <MuiThemeProvider theme={theme}>
      <Dialog open={props.isOpen} aria-labelledby="options-Dialog" onClose={props.handleCloseDialog}>
        <DialogTitle id="options-Dialog" className={classes.title}>
          Options
        </DialogTitle>
        <DialogContent className={classes.content}>
          {/* Audio Selector */}
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="voice-selector">Recitation</InputLabel>
            <Select
              value={props.selectedRecitationCode}
              onChange={event => props.handleRecitationChange(event.target.value)}
              inputProps={{
                name: "voice-selector",
                id: "voice-selector"
              }}
            >
              {props.recitations.map((item: Recitation) => (
                <MenuItem value={item.code} key={"recitation" + item.code}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <div className={classes.spacer} />

          {/* Font Scaling */}
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="font-scaling-selector">Text Scaling</InputLabel>
            <Select
              value={props.selectedFontScaling}
              onChange={event => props.handleFontScalingChange(event.target.value)}
              inputProps={{
                name: "font-scaling-selector",
                id: "font-scaling-selector"
              }}
            >
              {props.fontScalings.map((item: number) => (
                <MenuItem value={item} key={"font-scaling" + item.toString()}>
                  {item + "%"}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <div className={classes.spacer} />

          {/* Arabic Font */}
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="arabic-font-selector">Arabic Font</InputLabel>
            <Select
              value={props.selectedArabicFont}
              onChange={event => props.handleArabicFontChange(event.target.value)}
              inputProps={{
                name: "arabic-font-selector",
                id: "arabic-font-selector"
              }}
            >
              <MenuItem value={ArabicFont.Uthmani}>Uthmani</MenuItem>
              <MenuItem value={ArabicFont.Indopak}>IndoPak</MenuItem>
              <MenuItem value={ArabicFont.Scheherazade}>Scheherazade</MenuItem>
              <MenuItem value={ArabicFont.System}>System</MenuItem>
            </Select>
          </FormControl>

          <div className={classes.spacer} />

          {/* Verse Repeat Selector*/}
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="verse-repeat-selector">Verse Repeat</InputLabel>
            <Select
              value={props.verseRepeat}
              onChange={event => props.handleVerseRepeatChange(+event.target.value)}
              inputProps={{
                name: "verse-repeat-selector",
                id: "verse-repeat-selector"
              }}
            >
              {props.verseRepeats.map((item: number) => (
                <MenuItem value={item} key={"verse-repeat" + item.toString()}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <div className={classes.spacer} />

          {/* AutoScroll Selector */}
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="autoscroll-selector">Auto Scroll</InputLabel>
            <Select
              value={props.selectedAutoScroll.toString()}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChange={(event: any) => props.handleAutoScrollChange(JSON.parse(event.target.value.toString()))} // convert string value to boolean
              inputProps={{
                name: "autoscroll-selector",
                id: "autoscroll-selector"
              }}
            >
              <MenuItem value="true">On</MenuItem>
              <MenuItem value="false">Off</MenuItem>
            </Select>
          </FormControl>

          <div className={classes.spacer} />

          {/* Auto Advance Chapter Selector */}
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="auto-advance-chapter-selector">Auto Next Chapter</InputLabel>
            <Select
              value={props.autoAdvanceChapter.toString()}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChange={(event: any) => props.handleAutoAdvanceChapter(JSON.parse(event.target.value.toString()))} // convert string value to boolean
              inputProps={{
                name: "auto-advance-chapter-selector",
                id: "auto-advance-chapter-selector"
              }}
            >
              <MenuItem value="true">On</MenuItem>
              <MenuItem value="false">Off</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>

        <DialogActions>
          <Button onClick={props.handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </MuiThemeProvider>
  );
}

export default DialogSettings;
