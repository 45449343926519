import React from "react";
import { Chapter } from "../models/Chapter";
import { Recitation } from "../models/Recitation";
import ReactAudioPlayer from "react-audio-player";

interface AudioProps {
  chapter: Chapter;
  verseNumber: number;
  recitation: Recitation;
  isAudioPlaying: boolean;
  isAudioPlaying2: boolean;
  audioEnded: Function;
  verseRepeat: number;
}

function AudioComponent(props: AudioProps) {
  const { chapter, verseNumber, verseRepeat, recitation, isAudioPlaying, isAudioPlaying2, audioEnded } = props;

  const [verseRepeatCounter, setVerseRepeatCounter] = React.useState(1);
  const [forceRepeatVerseToggle, setForceRepeatVerseToggle] = React.useState(true);

  const playerA = React.useRef(null);
  const playerB = React.useRef(null);

  function getFileNumberWithLeadingZeros(num: number) {
    return ("00" + num).slice(-3);
  }

  function getVolume() {
    let volume: number;
    volume = recitation.scaling;
    return volume;
  }

  function getArabicUrl(vNumber: number) {
    let url: string;
    if (vNumber === 0 && recitation.bismiSingle) {
      url = `${recitation.baseUrl}001${recitation.seperator}001.mp3`;
    } else {
      url = `${recitation.baseUrl}${getFileNumberWithLeadingZeros(chapter.chapterNumber)}${
        recitation.seperator
      }${getFileNumberWithLeadingZeros(vNumber)}.mp3`;
    }
    return url;
  }

  function getUrl(vNumber: number) {
    let url: string;
    url = getArabicUrl(vNumber);
    return url;
  }

  // when enough audio data is loaded, load next file (for caching)
  React.useEffect(() => {
    playerA.current.audioEl.oncanplaythrough = function() {
      const currentAudioUrl = playerA.current.audioEl.src;
      let nextVerseNumber = getFileNumberWithLeadingZeros(
        +currentAudioUrl.substring(currentAudioUrl.length - 7, currentAudioUrl.length - 4) + 1
      );
      let nextAudioUrl =
        currentAudioUrl.substring(0, currentAudioUrl.length - 7) +
        nextVerseNumber +
        currentAudioUrl.substring(currentAudioUrl.length, currentAudioUrl.length - 4);
      playerB.current.audioEl.src = nextAudioUrl;
    };
  }, []);

  React.useEffect(() => {
    setVerseRepeatCounter(verseRepeat);
  }, [verseRepeat, verseNumber]);

  function handleAudioTrackEnded(event: any) {
    if (verseRepeatCounter === 1) {
      setVerseRepeatCounter(verseRepeat);
      audioEnded();
    } else {
      setVerseRepeatCounter(verseRepeatCounter - 1);
      setForceRepeatVerseToggle(false);
      setForceRepeatVerseToggle(true);
    }
  }

  React.useEffect(() => {
    if (!isAudioPlaying || !isAudioPlaying2) {
      playerA.current.audioEl.pause();
    }
  }, [isAudioPlaying, isAudioPlaying2]);

  React.useEffect(() => {
    setVerseRepeatCounter(verseRepeat);
  }, [verseRepeat]);

  return (
    <div>
      <ReactAudioPlayer
        src={isAudioPlaying && isAudioPlaying2 && forceRepeatVerseToggle ? getUrl(verseNumber) : null}
        autoPlay={isAudioPlaying && isAudioPlaying2}
        onEnded={handleAudioTrackEnded}
        muted={!isAudioPlaying || !isAudioPlaying2} // keep this - somehow, on iPhone 5, things do not work without it
        ref={playerA}
        volume={getVolume()}
      />
      <ReactAudioPlayer autoPlay={false} muted={true} ref={playerB} />
    </div>
  );
}

export default AudioComponent;
