import React from "react";
import { Helmet } from "react-helmet";
import { Chapter } from "../models/Chapter";

interface MetaTagsProps {
  chapter: Chapter;
}

function MetaTags(props: MetaTagsProps) {
  const chapter = props.chapter;
  const chapterNumber = props.chapter.chapterNumber;

  const baseUrl = "https://www.cloudquran.com";
  const iconUrl = "https://www.cloudquran.com/images/quran-icon.png";
  const infographicUrl = "https://www.cloudquran.com/images/quran-infographic.jpg";
  const titleChapter1 = `Quran القرآن الكريم In Arabic. Reading and Audio Online`;
  const descriptionChapter1 = `The Holy Koran text and recitations القرآن الكريم و تلاوات. Allah's Book in Modern Interface. Easy to use and Mobile Ready. Abdul Bassit, Al Afasy, Al Sudais, Al Ghamidi, Al Shuraim, Basfar, Al Hudhaify, Al Husary.`;

  let title;
  let description;
  let url;
  if (chapter.chapterNumber === 1) {
    title = titleChapter1;
    description = descriptionChapter1;
    url = baseUrl;
  } else {
    title = `سورة ${chapter.nameArabic} Surah ${chapter.nameTrans.replace("-", " ")} القرآن الكريم Quran ${chapter.chapterNumber} Online`;

    description = `Quran Surah ${chapter.nameTrans.replace("-", " ")} سورة ${
      chapter.nameArabic
    } In Arabic, with beautiful recitations. Read and listen online. Sura ${chapter.chapterNumber}. ${
      chapter.nameEnglish
    }. Abdul Bassit, Al Afasy, Al Sudais, Al Ghamidi, Al Shuraim, Basfar, Al Hudhaify, Al Husary.`;
    url = `${baseUrl}/${chapterNumber}`;
  }

  return (
    <Helmet
      // structured data
      script={[
        {
          type: "application/ld+json",
          innerHTML: `{
            "@context": "https://schema.org",
            "@type": "Website",
            "url": "${url}",
            "name": "CloudQuran",
            "description": "${description}",      
            "author": "Talal Itani",
            "logo": "${iconUrl}",
            "image": "${iconUrl}"
          }`
        }
      ]}
    >
      <html lang="en" />

      <link rel="canonical" href={url} />

      {/* meta tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="author" content="Talal Itani" />
      <meta name="robots" content="index, follow" />

      <meta property="og:url" content={url} />
      <meta property="og:type" content="book" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={infographicUrl} />
      <meta property="og:image:secure_url" content={iconUrl} />
      <meta property="og:site_name" content="Quran القرآن الكريم" />
      <meta property="fb:admins" content="683971979" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@ClearQuran" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:creator" content="@ClearQuran" />
      <meta name="twitter:image" content={infographicUrl} />
    </Helmet>
  );
}

export default MetaTags;
