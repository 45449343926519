import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { scroller, Events } from "react-scroll";
import { Chapter } from "../models/Chapter";
import { VerseModel } from "../models/VerseModel";
import { VerseLocationForText } from "../models/VerseLocationForText";
import "../css/global-styles.css";
import { ToHindi } from "../common/ToHindi";

const appbarHeight = 64;
const verticalOffset = 6;

const useStyles = makeStyles(() => ({
  chapterTitle: {
    fontFamily: "Uthmani",
    fontSize: "35px",
    fontWeight: "normal",
    color: "#000",
    padding: "0px",
    margin: "25px 0px 40px 0px",
    lineHeight: "1rem",
    ["@media (min-width:360px)"]: {
      fontSize: "35px"
    },
    ["@media (min-width:400px)"]: {
      fontSize: "35px"
    }
  },
  verse: {
    color: "#222",
    lineHeight: "1.6",
    padding: "8px 0px 5px 0px",
    flexGrow: 1,
    flexBasis: 0,
    cursor: "pointer",
    borderTop: "1px solid #ffffff",
    borderBottom: "1px solid #ffffff"
  },
  verseSelected: {
    backgroundColor: "#f6fef6",
    borderTop: "1px solid #BDFCC9",
    borderBottom: "1px solid #BDFCC9"
  },
  verseNumber: {
    color: "#333",
    fontSize: "75%",
    fontFamily: '"Traditional Arabic", "Times New Roman", serif'
  }
}));

interface QuranTextProps {
  chapter: Chapter;
  verseLocation: VerseLocationForText;
  fontScaling: number;
  arabicFontClassName: string;
  arabicFontScaling: number;
  chapterRenderingDone: Function;
  verseScrollingDone: Function;
  verseClicked: Function;
}

function QuranText(props: QuranTextProps) {
  const {
    chapter,
    verseLocation,
    fontScaling,
    arabicFontClassName,
    arabicFontScaling,
    chapterRenderingDone,
    verseScrollingDone,
    verseClicked
  } = props;
  const classes = useStyles(props);

  function handleVerseClicked(event: React.MouseEvent<HTMLElement>) {
    verseClicked(Number(event.currentTarget.id.replace("verse", "")));
  }

  React.useEffect(() => {
    Events.scrollEvent.register("end", function(to) {
      // perform check, because scroll also happens in navigation drawer
      if (to.startsWith("verse")) {
        verseScrollingDone(+to.replace("verse", ""));
      }
    });
  }, []);

  function isElementInViewport(element: HTMLElement, yOffset: number) {
    try {
      var rect = element.getBoundingClientRect();
      return (
        rect.top - yOffset >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) /*or $(window).height() */
      );
    } catch (error) {
      return true;
    }
  }

  function isElementScrolledUp(element: HTMLElement, yOffset: number) {
    try {
      var rect = element.getBoundingClientRect();
      return rect.top - yOffset >= 0;
    } catch (error) {
      return true;
    }
  }

  React.useEffect(() => {
    var itemVerse = document.getElementById("verse" + verseLocation.verseNumber);
    var isVerseInViewPort = isElementInViewport(itemVerse, +appbarHeight - verticalOffset);
    var itemTitles = document.getElementById("chapter-titles");
    var isTitlesScrolledUp = isElementScrolledUp(itemTitles, +appbarHeight - verticalOffset);

    if (verseLocation.isScrollToVerse) {
      if (!document.hidden) {
        // scrolling done only if document is visible
        if (verseLocation.verseNumber === 0 || verseLocation.verseNumber === 1) {
          if (!isTitlesScrolledUp) {
            // if already on top, don't scroll
            let option = {
              smooth: verseLocation.isAnimateScroll ? true : false,
              offset: -appbarHeight - 300 // force all the way up
            };
            scroller.scrollTo("verse" + verseLocation.verseNumber, option);
          } else {
            verseScrollingDone(verseLocation.verseNumber);
          }
        } else if (!isVerseInViewPort) {
          // if already in viewport, don't scroll
          let option = {
            smooth: "verseLocation.isAnimateScroll ? true : false",
            offset: -appbarHeight - verticalOffset
          };
          scroller.scrollTo("verse" + verseLocation.verseNumber, option);
        } else {
          verseScrollingDone(verseLocation.verseNumber);
        }
      } else {
        verseScrollingDone(verseLocation.verseNumber);
      }
    } else {
      // do not scroll, since document is hidden
      verseScrollingDone(verseLocation.verseNumber);
    }
  }, [verseLocation.verseNumber, verseLocation.isForceNewVerseNumber]);

  React.useEffect(() => {
    chapterRenderingDone();
  }, [chapter.chapterNumber]);

  let arabicBlockStyle = {
    fontSize: ((fontScaling * arabicFontScaling) / 100).toString() + "rem"
  };

  return (
    <div>
      {/* chapter titles */}
      <h2 className={classes.chapterTitle} dir="rtl" lang="ar" id="chapter-titles">
        سورة {chapter.nameArabic}
      </h2>
      {/* verses */}
      {chapter.verses.map((verse: VerseModel) => (
        <div
          id={"verse" + verse.verseNumber.toString()}
          key={"verse" + verse.verseNumber.toString()}
          onClick={handleVerseClicked}
          className={classes.verse + " " + (verseLocation.verseNumber === verse.verseNumber ? classes.verseSelected : null)}
          style={arabicBlockStyle}
          dir="rtl"
          lang="ar"
        >
          {verse.verseNumber !== 0 && <span className={classes.verseNumber}>﴿{ToHindi(verse.verseNumber, "ar")}﴾&nbsp;</span>}
          <span className={arabicFontClassName}>{verse.verseString}</span>
        </div>
      ))}
    </div>
  );
}

export default QuranText;
