import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import NavigateNext from "@material-ui/icons/NavigateNext";
import NavigateBefore from "@material-ui/icons/NavigateBefore";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import MenuItem from "@material-ui/core/MenuItem";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Menu from "@material-ui/core/Menu";
import AudioComponent from "../components/AudioComponent";
import { ChapterVerseLocation } from "../models/ChapterVerseLocation";
import GetChapter from "../components/GetChapter";
import ProgressBar from "../components/ProgressBar";
import AppBarChapter from "../components/AppBarChapter";
import DrawerChapter from "../components/DrawerChapter";
import DialogSettings from "../components/DialogSettings";
import Search from "../components/Search";
import MetaTags from "../components/MetaTags";
import QuranText from "../components/QuranText";
import { graphql } from "gatsby";
import { Chapter } from "../models/Chapter";
import { VerseModel } from "../models/VerseModel";
import { VerseLocationForText } from "../models/VerseLocationForText";
import * as localStorage from "local-storage";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { ArabicFont } from "../enums/ArabicFont";
import { RECITATIONS } from "../data/Recitations";
import { Recitation } from "../models/Recitation";
import "../css/global-styles.css";
import ShareVersesDialog from "../components/ShareVersesDialog";
import Snackbar from "@material-ui/core/Snackbar";

let isForceCacheChapter = true; // For chapter after initial chapter
let forceNewVerseNumberForText = 0; // To force scrolling to verse, even when verse number does not change

let urlHash = "";
let bookmark: ChapterVerseLocation;
const maxWidth = "650px";

const defaultFontSizeUthmani = 1.5; // in rem
const defaultFontSizeIndoPak = 1.6; // in rem
const defaultFontSizeScheherazade = 1.7; // in rem
const defaultFontSizeSystem = 1.45; // in rem

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function QuranChapter(props: any) {
  const drawerWidth = 235;
  let chaptersInfo;

  const recitations: Recitation[] = RECITATIONS;

  var fontScalings = [200, 170, 135, 120, 110, 100, 95, 90, 85];
  var verseRepeats = [];
  for (var i = 1; i <= 20; i++) {
    verseRepeats.push(i);
  }

  const useStyles = makeStyles(theme => ({
    floatingButton: {},
    verseMenuTitle: {
      padding: "10px 00px 5px 15px",
      fontWeight: "bold",
      fontSize: "16px",
      width: "125px"
    },
    content: {
      maxWidth: maxWidth,
      margin: "auto"
    },
    root: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#ffffff"
    },
    main: {
      marginLeft: "0",
      [theme.breakpoints.up("md")]: {
        marginLeft: `calc(${drawerWidth}px)`
      },
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: "12px", // verse selected indicator occupies more padding (in Verse Component)
      paddingRight: "12px" // verse selected indicator occupies more padding (in Verse Component)
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth
    },
    nextButton: {
      color: "#333",
      paddingLeft: "5px",
      paddingRight: "5px"
    },
    prevButton: {
      color: "#555",
      paddingLeft: "5px",
      paddingRight: "5px"
    },
    nextPrevButtons: {
      margin: "28px 20px 0px 10px",
      float: "right"
    },
    fontUthmani: {
      fontFamily: "Uthmani"
    },
    fontIndopak: {
      fontFamily: "Indopak"
    },
    fontScheherazade: {
      fontFamily: "Scheherazade"
    },
    fontSystem: {
      fontFamily: "System"
    }
  }));

  if (typeof document !== "undefined") {
    document.body.style.backgroundColor = "#ffffff";
  }

  const classes = useStyles(props);
  const theme = useTheme();

  chaptersInfo = props.data.allQuranInfoJson;
  const defaultRecitationCode = "addussary-64";
  const defaultIsAutoScroll = true;
  const defaultIsAutoNextChapter = true;
  const defaultFontScaling = 100;
  const defaultVerseRepeat = 1;
  const defaultChapter = props.data.allTranslationJson.nodes[0];
  const defaultArabicFont = ArabicFont.Uthmani;

  // state to save in local storage
  const [recitationCode, setRecitationCode] = React.useState(defaultRecitationCode);
  const [fontScaling, setFontScaling] = React.useState(defaultFontScaling);
  const [arabicFont, setArabicFont] = React.useState(defaultArabicFont);
  const [verseRepeat, setVerseRepeat] = React.useState(defaultVerseRepeat);
  const [isAutoScroll, setIsAutoScroll] = React.useState(defaultIsAutoScroll);
  const [isAutoNextChapter, setIsAutoNextChapter] = React.useState(defaultIsAutoNextChapter);
  //
  const [anchorVerseMenu, setAnchorVerseMenu] = React.useState(null);
  const [isAudioPlaying, setIsAudioPlaying] = React.useState(false);
  const [isAudioPlaying2, setIsAudioPlaying2] = React.useState(true);
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [isOptionsDialogOpen, setIsOptionsDialogOpen] = React.useState(false);
  const [chapter, setChapter] = React.useState(defaultChapter);
  const [verseNumberForAudio, setVerseNumberForAudio] = React.useState(chapter.verses[0].verseNumber);
  const [isProgressBarVisible, setIsProgressBarVisible] = React.useState(false);
  const [isSearchOpen, setIsSearchOpen] = React.useState(false);

  const defaultVerseLocationForText = new VerseLocationForText(defaultChapter.verses[0].verseNumber, false, false, 0);
  const defaultChapterVerseLocation = new ChapterVerseLocation(defaultChapter.chapterNumber, defaultVerseLocationForText);

  const [verseLocationForText, setVerseLocationForText] = React.useState(defaultVerseLocationForText);
  const [chapterVerseLocation, setChapterVerseLocation] = React.useState(defaultChapterVerseLocation);

  const [isShareVersesDialogOpen, setIsShareVersesDialogOpen] = React.useState(false);

  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  if (typeof window !== "undefined") {
    urlHash = window.location.hash;
  }

  React.useEffect(() => {
    bookmark = localStorage.get<ChapterVerseLocation>("BOOKMARK") != null ? localStorage.get<ChapterVerseLocation>("BOOKMARK") : null;
    // set state from local storage
    setRecitationCode(
      localStorage.get<string>("RECITATION-CODE") != null ? localStorage.get<string>("RECITATION-CODE") : defaultRecitationCode
    );
    setFontScaling(localStorage.get<number>("FONT-SCALING") != null ? localStorage.get<number>("FONT-SCALING") : defaultFontScaling);
    setArabicFont(localStorage.get<ArabicFont>("ARABIC-FONT") != null ? localStorage.get<ArabicFont>("ARABIC-FONT") : defaultArabicFont);
    setVerseRepeat(localStorage.get<number>("VERSE-REPEAT") != null ? localStorage.get<number>("VERSE-REPEAT") : defaultVerseRepeat);
    setIsAutoScroll(
      localStorage.get<boolean>("IS-AUTO-SCROLL") != null ? localStorage.get<boolean>("IS-AUTO-SCROLL") : defaultIsAutoScroll
    );
    setIsAutoNextChapter(
      localStorage.get<boolean>("IS-AUTO-NEXT-CHAPTER") != null
        ? localStorage.get<boolean>("IS-AUTO-NEXT-CHAPTER")
        : defaultIsAutoNextChapter
    );
    // hash from Url
    if (urlHash != "") {
      let vNum = +urlHash.substring(1);
      if (
        vNum != null &&
        !isNaN(vNum) &&
        vNum >= defaultChapter.verses[0].verseNumber &&
        vNum <= defaultChapter.verses[defaultChapter.verses.length - 1].verseNumber
      ) {
        setVerseLocationForText(new VerseLocationForText(vNum, true, true, 0));
      }
    }
  }, []);

  React.useEffect(() => {
    localStorage.set<string>("RECITATION-CODE", recitationCode);
    localStorage.set<number>("FONT-SCALING", fontScaling);
    localStorage.set<ArabicFont>("ARABIC-FONT", arabicFont);
    localStorage.set<number>("VERSE-REPEAT", verseRepeat);
    localStorage.set<boolean>("IS-AUTO-SCROLL", isAutoScroll);
    localStorage.set<boolean>("IS-AUTO-NEXT-CHAPTER", isAutoNextChapter);
  }, [recitationCode, arabicFont, fontScaling, verseRepeat, isAutoScroll, isAutoNextChapter]);

  function handleVerseRepeatInput(verseRepeat: NumberConstructor) {
    setVerseRepeat(verseRepeat);
  }

  function getArabicFontClassName(arabicFont: ArabicFont) {
    if (arabicFont === ArabicFont.Uthmani) {
      return classes.fontUthmani;
    } else if (arabicFont === ArabicFont.Indopak) {
      return classes.fontIndopak;
    } else if (arabicFont === ArabicFont.Scheherazade) {
      return classes.fontScheherazade;
    } else if (arabicFont === ArabicFont.System) {
      return classes.fontSystem;
    }
  }

  function getArabicFontScaling(arabicFont: ArabicFont) {
    if (arabicFont === ArabicFont.Uthmani) {
      return defaultFontSizeUthmani;
    } else if (arabicFont === ArabicFont.Indopak) {
      return defaultFontSizeIndoPak;
    } else if (arabicFont === ArabicFont.Scheherazade) {
      return defaultFontSizeScheherazade;
    } else if (arabicFont === ArabicFont.System) {
      return defaultFontSizeSystem;
    }
  }

  function handlePlayPause() {
    if (!isAudioPlaying) {
      // We are really checking if audio is playing - because the button toggles the state
      const verseLocation = new VerseLocationForText(verseLocationForText.verseNumber, true, true, ++forceNewVerseNumberForText);
      setVerseLocationForText(verseLocation);
      // @ts-ignore
      window.dataLayer.push({
        event: "verse-repeat",
        verseRepeat: verseRepeat.toString()
      });
      // @ts-ignore
      window.dataLayer.push({
        event: "auto-scroll",
        autoScroll: isAutoScroll.toString()
      });
      // @ts-ignore
      window.dataLayer.push({
        event: "auto-next-chapter",
        autoNextChapter: isAutoNextChapter.toString()
      });
    }
    setIsAudioPlaying(!isAudioPlaying);
    setIsAudioPlaying2(true);
  }

  function getRecitation(recitationCode: string): Recitation {
    try {
      let recitation = recitations.filter(recitation => recitation.code === recitationCode)[0];
      if (recitation != null) {
        return recitation;
      } else {
        return getRecitation(defaultRecitationCode);
      }
    } catch (error) {
      return getRecitation(defaultRecitationCode);
    }
  }

  function handleNewChapter(newChNum: number) {
    setIsDrawerOpen(false);
    setIsAudioPlaying2(false);
    const verseLocationForText = new VerseLocationForText(
      newChNum === 1 || newChNum === 9 ? 1 : 0,
      true,
      false,
      ++forceNewVerseNumberForText
    );
    if (newChNum === chapter.chapterNumber) {
      setVerseLocationForText(verseLocationForText);
    } else {
      setIsProgressBarVisible(true);
      const chapterVerseLocation = new ChapterVerseLocation(newChNum, verseLocationForText);
      setChapterVerseLocation(chapterVerseLocation);
    }
    // @ts-ignore
    window.dataLayer.push({
      event: "new-chapter",
      chapterNumber: newChNum.toString()
    });
    // @ts-ignore
    window.dataLayer.push({
      event: "font-scaling",
      fontScaling: fontScaling.toString()
    });
  }

  function handleNextButtonClicked() {
    handleNewChapter(chapter.chapterNumber < 114 ? chapter.chapterNumber + 1 : 1);
    // @ts-ignore
    window.dataLayer.push({
      event: "next-chapter-button-clicked"
    });
  }

  function handlePrevButtonClicked() {
    handleNewChapter(chapter.chapterNumber > 1 ? chapter.chapterNumber - 1 : 114);
    // @ts-ignore
    window.dataLayer.push({
      event: "prev-chapter-button-clicked"
    });
  }

  function getNextChapter() {
    handleNewChapter(chapter.chapterNumber < 114 ? chapter.chapterNumber + 1 : 1);
    // @ts-ignore
    window.dataLayer.push({
      event: "get-next-chapter-automatic"
    });
  }

  function handleVerseClicked(vNumber: number) {
    setIsAudioPlaying2(false);
    if (vNumber === verseLocationForText.verseNumber) {
      setVerseLocationForText(new VerseLocationForText(vNumber, false, false, ++forceNewVerseNumberForText));
    } else {
      setVerseLocationForText(new VerseLocationForText(vNumber, false, false, 0));
    }
    // @ts-ignore
    window.dataLayer.push({
      event: "verse-clicked"
    });
  }

  function handleVerseMenuItemClick(index: number) {
    setIsAudioPlaying2(false);
    setAnchorVerseMenu(null);
    if (index === verseLocationForText.verseNumber) {
      setVerseLocationForText(new VerseLocationForText(index, true, true, ++forceNewVerseNumberForText));
    } else {
      setVerseLocationForText(new VerseLocationForText(index, true, true, 0));
    }
  }

  function handleAudioEnded() {
    if (verseNumberForAudio < chapter.verses[chapter.verses.length - 1].verseNumber) {
      setVerseLocationForText(new VerseLocationForText(verseNumberForAudio + 1, isAutoScroll, true, 0));
    } else if (isAutoNextChapter === true) {
      getNextChapter();
    }
    // @ts-ignore
    window.dataLayer.push({
      event: "audio-verse-ended",
      recitation: getRecitation(recitationCode).name
    });
  }

  function handleChapterRenderingDone() {
    setIsProgressBarVisible(false);
    setVerseLocationForText(chapterVerseLocation.verseLocationForText);
  }

  function handleVerseScrollingDone(verseNumber: number) {
    setIsAudioPlaying2(true);
    setVerseNumberForAudio(verseNumber);
    window.history.replaceState(null, null, "#" + verseNumber.toString());
  }

  function gotoLocation(gotoLocation: ChapterVerseLocation) {
    setIsAudioPlaying2(false);
    setIsSearchOpen(false);
    if (chapter.chapterNumber === gotoLocation.chapterNumber) {
      setVerseLocationForText(gotoLocation.verseLocationForText);
    } else {
      setChapterVerseLocation(gotoLocation);
    }
  }

  function handleNewChapterLoaded(newChapter: Chapter) {
    setChapter(newChapter);
    setVerseLocationForText(chapterVerseLocation.verseLocationForText);
    window.history.replaceState(null, null, "/" + newChapter.chapterNumber.toString());
  }

  function handleBookmarkClicked() {
    const verseLocation = new VerseLocationForText(verseNumberForAudio, true, true, 0);
    bookmark = new ChapterVerseLocation(chapter.chapterNumber, verseLocation);
    localStorage.set<ChapterVerseLocation>("BOOKMARK", bookmark);
    setOpenSnackbar(true);
  }

  function handleGotoBookmarkClicked() {
    if (bookmark != null) {
      gotoLocation(bookmark);
    }
  }

  function handleShareVersesClicked() {
    setIsShareVersesDialogOpen(true);
  }

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div className={classes.root}>
      <main className={classes.main}>
        <div className={classes.toolbar} />
        <article className={classes.content}>
          <QuranText
            chapter={chapter}
            fontScaling={fontScaling}
            arabicFontClassName={getArabicFontClassName(arabicFont)}
            arabicFontScaling={getArabicFontScaling(arabicFont)}
            verseClicked={handleVerseClicked}
            chapterRenderingDone={() => handleChapterRenderingDone()}
            verseScrollingDone={(verseNumber: number) => handleVerseScrollingDone(verseNumber)}
            verseLocation={verseLocationForText}
          />

          <ButtonGroup variant="contained" size="medium" className={classes.nextPrevButtons}>
            <Button className={classes.nextButton} onClick={handleNextButtonClicked} aria-label="Next Chapter">
              <NavigateBefore />
              Next &ensp;
            </Button>
            <Button className={classes.prevButton} onClick={handlePrevButtonClicked} aria-label="Prev Chapter">
              <NavigateNext />
            </Button>
          </ButtonGroup>
        </article>
        <AudioComponent
          isAudioPlaying={isAudioPlaying}
          isAudioPlaying2={isAudioPlaying2}
          chapter={chapter}
          verseNumber={verseNumberForAudio}
          verseRepeat={verseRepeat}
          recitation={getRecitation(recitationCode)}
          audioEnded={() => handleAudioEnded()}
        />
        {chapterVerseLocation.chapterNumber !== chapter.chapterNumber && (
          <GetChapter
            chapterNumber={chapterVerseLocation.chapterNumber}
            isForCaching={false}
            newChapterLoaded={handleNewChapterLoaded}
            newChapterCached={null}
          />
        )}
        {(chapterVerseLocation.chapterNumber !== chapter.chapterNumber || isForceCacheChapter) && (
          <GetChapter
            chapterNumber={chapterVerseLocation.chapterNumber + 1}
            isForCaching={true}
            newChapterLoaded={null}
            newChapterCached={() => (isForceCacheChapter = false)}
          />
        )}
        <Box m={12} />
      </main>

      {/* Verse Selection Menu */}
      <Menu id="verse-selection-menu" anchorEl={anchorVerseMenu} open={Boolean(anchorVerseMenu)} onClose={() => setAnchorVerseMenu(null)}>
        <Typography className={classes.verseMenuTitle}> Verse &#x2022; Aya </Typography>
        {chapter.verses.map((item: VerseModel) => (
          <MenuItem
            key={"verse" + item.verseNumber}
            selected={item.verseNumber === verseLocationForText.verseNumber}
            onClick={() => handleVerseMenuItemClick(item.verseNumber)}
          >
            {item.verseNumber}
          </MenuItem>
        ))}
      </Menu>

      {/* Settings Dialog */}
      <DialogSettings
        selectedRecitationCode={recitationCode}
        recitations={recitations}
        handleRecitationChange={(recitationCode: string) => setRecitationCode(recitationCode)}
        fontScalings={fontScalings}
        handleFontScalingChange={(fontScaling: number) => setFontScaling(fontScaling)}
        selectedArabicFont={arabicFont}
        handleArabicFontChange={(arabicFont: ArabicFont) => setArabicFont(arabicFont)}
        selectedFontScaling={fontScaling}
        selectedAutoScroll={isAutoScroll}
        handleAutoScrollChange={(autoScroll: boolean) => setIsAutoScroll(autoScroll)}
        autoAdvanceChapter={isAutoNextChapter}
        handleAutoAdvanceChapter={(autoAdvanceChapter: boolean) => setIsAutoNextChapter(autoAdvanceChapter)}
        isOpen={isOptionsDialogOpen}
        handleCloseDialog={() => setIsOptionsDialogOpen(false)}
        verseRepeats={verseRepeats}
        handleVerseRepeatChange={handleVerseRepeatInput}
        verseRepeat={verseRepeat}
      />

      {/* Share Verses Dialog */}
      <ShareVersesDialog
        currentVerseNumber={verseLocationForText.verseNumber}
        chapter={chapter}
        isOpen={isShareVersesDialogOpen}
        handleCloseDialog={() => setIsShareVersesDialogOpen(false)}
      />

      {/* Search */}
      <Search
        fontScaling={fontScaling}
        isOpen={isSearchOpen}
        handleClose={() => setIsSearchOpen(false)}
        searchVerseClick={gotoLocation}
        chaptersInfo={chaptersInfo}
        arabicFontClassName={getArabicFontClassName(arabicFont)}
        arabicFontScaling={getArabicFontScaling(arabicFont)}
      />

      <ProgressBar isVisible={isProgressBarVisible} />
      <MetaTags chapter={chapter} />
      <DrawerChapter
        chaptersInfo={chaptersInfo}
        chapterNumber={chapter.chapterNumber}
        isOpen={isDrawerOpen}
        handleCloseDrawer={() => setIsDrawerOpen(false)}
        newChapterClicked={(chapterNumber: number) => handleNewChapter(chapterNumber)}
        isWindowWide={useMediaQuery(theme.breakpoints.up("md"))}
      />
      <AppBarChapter
        chapterNumber={chapter.chapterNumber}
        isAudioPlaying={isAudioPlaying}
        onClickMenuButton={() => setIsDrawerOpen(true)}
        onClickPlayPauseButton={handlePlayPause}
        onClickVerticalSwapButton={(event: React.MouseEvent<HTMLElement>) => setAnchorVerseMenu(event.currentTarget)}
        onClickSearchButton={() => setIsSearchOpen(true)}
        onClickSettingsButton={() => setIsOptionsDialogOpen(true)}
        onClickTitle={() => (document.location.href = "/")}
        bookmarkClicked={handleBookmarkClicked}
        gotoBookmarkClicked={handleGotoBookmarkClicked}
        shareVersesClicked={handleShareVersesClicked}
      />
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
        message={"Verse " + chapter.chapterNumber + ":" + verseLocationForText.verseNumber + " bookmarked"}
      />
    </div>
  );
}

QuranChapter.propTypes = {
  // Injected by the documentation to work in an iframe.
  // You won't need it on your project.
  container: PropTypes.object
};

export default QuranChapter;

export const quranQuery = graphql`
  query QuranInfoJson2($slug: Int!) {
    allTranslationJson(filter: { chapterNumber: { eq: $slug } }) {
      nodes {
        verses {
          verseNumber
          verseString
        }
        nameEnglish
        nameTrans
        nameArabic
        chapterNumber
      }
    }
    allQuranInfoJson {
      edges {
        node {
          nVerses
          nameEnglish
          nameTrans
          nameArabic
        }
      }
    }
  }
`;
